import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Link, Navigate } from "react-router-dom";
import { selectIsHandheldToolbarVisible } from "store/slices/uiSlice";
import { CartProduct } from "../components/cart/cart-product";
import { LoadingWrapper } from "../components/layout/loading-wrapper";
import { PageTitleBlock } from "../components/layout/page-title-block";
import { ShipmentInstructionsModal } from "../components/modals/shipment-instructions-modal";
import { LoadingSpinnerButton } from "../components/ui/buttons/loading-spinner-button";
import { CartzillaIcon, CartzillaIconName } from "../components/ui/icons/cartzilla-icon";
import { ICartProduct } from "../models/api/ICartProduct";
import { IBaseOrderRow } from "../models/api/IOrderRow";
import { routes } from "../router";
import { useAppSelector } from "../store/hooks";
import { selectCartComment, selectCartProducts, selectCartProductsSum, selectCartTransportSum } from "../store/slices/cartSlice";
import { useAddOrderMutation, useGetCartQuery, useGetPendingInvoicesQuery, useGetTryOrderQuery, useUpdateCartMutation } from "../api/ecosternApi";
import { selectCurrentClientId, selectCurrentClientInstructions, selectIsUserEcostern } from "../store/slices/userSlice";
import { debounce } from "../utils/utils";
import { getVAT } from "utils/tax.utils";
import { useAnalytics } from "hooks/use-analytics";
import { isApiError } from "models/api/IError";

const CART_STATEPARTS_SESSION_STORAGE_KEY = "ecostern/cart";

interface ICartViewState {
    isModalOpen: boolean;
    shipmentInstructions: string;
    isShipmentInstructionsConfirmed: boolean;
    comment: string | null;
}

interface StoredStateParts {
    shipmentInstructions: string;
    isShipmentInstructionsConfirmed: boolean;
    clientId: number;
}

export const CartView = () => {
    const intl = useIntl();
    const { track } = useAnalytics();
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const selectedClientShipmentInstructions = useAppSelector(selectCurrentClientInstructions);
    const isEcostern = useAppSelector(selectIsUserEcostern);
    const isHandheldToolbarVisible = useAppSelector(selectIsHandheldToolbarVisible);
    const { isLoading: isCartLoading, isError: isCartError } = useGetCartQuery((selectedClientId ?? skipToken) as SkipToken);
    const { data: pendingInvoices = [] } = useGetPendingInvoicesQuery(selectedClientId ?? skipToken);
    const [addOrder, { isLoading: isAddOrderLoading, isSuccess: isAddOrderSuccess, isError: isAddOrderError, error: addOrderError }] = useAddOrderMutation();
    const [updateCartComment, { isLoading: isCartCommentUpdateLoading, isSuccess: isCartCommentUpdateSuccess, isError: isCartCommentUpdateError }] =
        useUpdateCartMutation();
    const productsSum = useAppSelector(selectCartProductsSum);
    const cartProducts = useAppSelector(selectCartProducts);
    const cartComment = useAppSelector(selectCartComment);
    const { data: tryOrder } = useGetTryOrderQuery(
        {
            clientId: selectedClientId as number,
            rows: cartProducts.map(
                (p) =>
                    ({
                        productId: p.productId,
                        quantity: p.quantity,
                    } as IBaseOrderRow)
            ),
        },
        { skip: !selectedClientId }
    );
    const cartTransportSum = useAppSelector(selectCartTransportSum);
    const storedStateParts = sessionStorage.getItem(CART_STATEPARTS_SESSION_STORAGE_KEY);
    const storedStatePartsObj = storedStateParts ? JSON.parse(storedStateParts) : undefined;
    const storedState =
        storedStatePartsObj && storedStatePartsObj.clientId === selectedClientId
            ? {
                  shipmentInstructions: storedStatePartsObj.shipmentInstructions,
                  isShipmentInstructionsConfirmed: storedStatePartsObj.isShipmentInstructionsConfirmed,
              }
            : {};

    const [state, setState] = useState<ICartViewState>(
        Object.assign(
            {
                isModalOpen: false,
                shipmentInstructions: "",
                isShipmentInstructionsConfirmed: true,
                comment: null,
            },
            storedState
        )
    );

    const isAnyCartProductArchived = () => {
        return cartProducts.filter((cp) => !cp.product || !cp.product.isAllowedForWeb).length !== 0;
    };

    const isSubmitDisabled =
        !state.isShipmentInstructionsConfirmed ||
        cartProducts.length === 0 ||
        isAnyCartProductArchived() ||
        (tryOrder?.unavailableProducts ?? []).length > 0 ||
        isAddOrderLoading ||
        cartProducts.filter((cp) => cp.quantity < 1).length > 0;

    useEffect(() => {
        storeStatePartsInSessionStorage();
    });

    const storeStatePartsInSessionStorage = () => {
        if (!selectedClientId) return;
        const { shipmentInstructions, isShipmentInstructionsConfirmed }: Omit<StoredStateParts, "clientId" | "comment"> = state;
        const storedStateParts: StoredStateParts = { shipmentInstructions, isShipmentInstructionsConfirmed, clientId: selectedClientId };
        sessionStorage.setItem(CART_STATEPARTS_SESSION_STORAGE_KEY, JSON.stringify(storedStateParts));
    };

    const toggleShipmentInstructionsModal = () => {
        setState({ ...state, isModalOpen: !state.isModalOpen });
    };

    const saveShipmentInstructions = (newInstructions: string) => {
        setState({
            ...state,
            shipmentInstructions: newInstructions,
            isModalOpen: false,
            isShipmentInstructionsConfirmed: false,
        });
    };

    const onCommentFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const comment = e.currentTarget.value;
        setState({ ...state, comment });
        debouncedUpdateCartComment(comment);
    };

    const confirmInstructions = () => {
        setState({
            ...state,
            isShipmentInstructionsConfirmed: true,
        });
    };

    const debouncedUpdateCartComment = useCallback(
        debounce(async (_comment: string) => {
            try {
                await updateCartComment({ comment: _comment ?? null });
            } catch (err) {
                console.log(err);
                // User is shown error based on hook provided variable
            }
        }, 1000),
        []
    );

    const debouncedSendOrder = useCallback(
        debounce(async (_state: ICartViewState, _clientId: number, _cartProducts: ICartProduct[], _isMobile: boolean, _comment: string | null) => {
            if (_state.isShipmentInstructionsConfirmed && _clientId && _cartProducts.length > 0) {
                try {
                    debouncedUpdateCartComment.cancel();

                    await addOrder({
                        clientId: _clientId,
                        isMobile: _isMobile,
                        comment: _state.comment ?? _comment ?? "",
                        shipmentInstructions: _state.shipmentInstructions,
                        rows: _cartProducts.map(
                            (p) =>
                                ({
                                    productId: p.productId,
                                    quantity: p.quantity,
                                } as IBaseOrderRow)
                        ),
                    });

                    sessionStorage.removeItem(CART_STATEPARTS_SESSION_STORAGE_KEY);
                    track("order", { revenue: { amount: productsSum, currency: "EUR" } });
                } catch (err) {
                    console.log("err", err);
                    // User is shown error based on hook provided variable
                }
            }
        }, 300),
        []
    );

    const sendOrder = async () => {
        if (!selectedClientId) return;
        debouncedSendOrder(state, selectedClientId, cartProducts, isHandheldToolbarVisible, cartComment);
    };

    const getCartSumWithVAT = () => {
        return productsSum + getCartSumVAT();
    };

    const getCartSumVAT = () => {
        return productsSum * getVAT();
    };

    const getCartCommentState = () => {
        if (isCartCommentUpdateLoading) return <Spinner animation={"border"} style={{ height: "100%", width: "100%", opacity: 0.25 }} />;
        if (isCartCommentUpdateSuccess) return <CartzillaIcon icon={CartzillaIconName.CloudCheck} className={"text-success fs-sm"} />;
        if (isCartCommentUpdateError) return <CartzillaIcon icon={CartzillaIconName.Announcement} className={"text-primary fs-sm"} />;
        return null;
    };

    if (isAddOrderSuccess) return <Navigate to={routes.checkout.path} />;

    return (
        <>
            <PageTitleBlock routeName={"cart"} hasOverlap={true} />
            <LoadingWrapper isLoading={cartProducts.length > 0 && isCartLoading} isError={isCartError} className={"mt-3"}>
                <Container className={"pb-5 mb-2 mb-md-4"}>
                    <Row>
                        <Col as={"section"} lg={8}>
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-4 pb-sm-5 mt-1">
                                <h2 className="h6 text-light mb-0">
                                    <FormattedMessage id={"nav.products"} />
                                </h2>
                                <Link to={routes.products.path}>
                                    <Button variant={"outline-primary"} size={"sm"}>
                                        <CartzillaIcon icon={CartzillaIconName.ArrowLeft} className={"me-2"} />
                                        <FormattedMessage id={"nav.backToStore"} />
                                    </Button>
                                </Link>
                            </div>
                            <Row className={"mx-n2"}>
                                <Col className={"py-4"}>
                                    {cartProducts.map((cp) => (
                                        <CartProduct
                                            key={`checkout-${cp.productId}`}
                                            cartProduct={cp}
                                            isOutOfStock={!!tryOrder?.outOfStockProducts?.filter((p) => p === cp.productId).length}
                                            isUnavailable={!!tryOrder?.unavailableProducts?.filter((p) => p === cp.productId).length}
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={"border w-100 rounded-3 p-4"}>
                                        <div className={"h6 fs-sm"}>
                                            <FormattedMessage id="shoppingCart.delivery.title" />
                                        </div>
                                        <div className={"d-flex flex-column fs-sm"}>
                                            <div>
                                                <FormattedMessage id={"shoppingCart.delivery.location.north"} />
                                            </div>
                                            <div>
                                                <FormattedMessage id={"shoppingCart.delivery.location.east"} />
                                            </div>
                                            <div>
                                                <FormattedMessage id={"shoppingCart.delivery.location.west"} />
                                            </div>
                                            <div>
                                                <FormattedMessage id={"shoppingCart.delivery.location.south"} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} as={"aside"} className={"pt-4 pt-lg-0 ps-xl-5 clear"}>
                            <div className={"bg-white w-100 rounded-3 shadow-lg p-4 p-md-1"}>
                                <div className={"py-grid-gutter px-lg-grid-gutter"}>
                                    <div className={"mb-4 pb-4 border-bottom"}>
                                        <div className="text-center mb-4 pb-3 border-bottom">
                                            <h2 className="h6 mb-3 pb-1">
                                                <FormattedMessage id={"order.total"} />
                                            </h2>
                                            <h3 className="fw-normal">
                                                <FormattedNumber value={productsSum} style={"currency"} currency={"EUR"} />
                                            </h3>
                                            {cartTransportSum > 0 && (
                                                <div className={"text-primary mb-3"}>
                                                    <FormattedMessage
                                                        id={"shoppingCart.warning"}
                                                        values={{
                                                            transportSum: cartTransportSum,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div>
                                                <span className={"me-2"}>
                                                    <FormattedNumber value={getCartSumVAT()} style={"currency"} currency={"EUR"} />
                                                </span>
                                                <span className={"fs-sm"}>
                                                    <FormattedMessage
                                                        id={"order.totalsVAT"}
                                                        values={{ vatPercentage: intl.formatNumber(getVAT(), { style: "percent", maximumFractionDigits: 0 }) }}
                                                    />
                                                </span>
                                            </div>
                                            <div>
                                                <span className={"me-2"}>
                                                    <FormattedNumber value={getCartSumWithVAT()} style={"currency"} currency={"EUR"} />
                                                </span>
                                                <span className={"fs-sm"}>
                                                    <FormattedMessage id={"order.totalsTotalWithVAT"} />
                                                </span>
                                            </div>
                                        </div>
                                        <Accordion defaultActiveKey="0" className={"my-3"}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <FormattedMessage id={"order.shipmentInstructionsTitle"} />
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className={"mb-3"}>
                                                        {state.shipmentInstructions ? state.shipmentInstructions : selectedClientShipmentInstructions}
                                                    </div>
                                                    {isEcostern && (
                                                        <div className={"d-flex mb-3"}>
                                                            <>
                                                                {!state.isShipmentInstructionsConfirmed ? (
                                                                    <Button size={"sm"} onClick={confirmInstructions}>
                                                                        <FormattedMessage id={"order.confirmShipmentInstructions"} />
                                                                    </Button>
                                                                ) : (
                                                                    <Button size={"sm"} variant={"success"} disabled>
                                                                        <FormattedMessage id={"order.confirmedShipmentInstructions"} />
                                                                    </Button>
                                                                )}

                                                                <Button
                                                                    size={"sm"}
                                                                    variant={"outline-secondary"}
                                                                    className={"ms-2"}
                                                                    onClick={toggleShipmentInstructionsModal}
                                                                >
                                                                    <FormattedMessage id={"order.editShipmentInstructions"} />
                                                                </Button>
                                                            </>
                                                            <ShipmentInstructionsModal
                                                                isVisible={state.isModalOpen}
                                                                instructions={
                                                                    state.shipmentInstructions ? state.shipmentInstructions : selectedClientShipmentInstructions
                                                                }
                                                                onSave={saveShipmentInstructions}
                                                                onClose={toggleShipmentInstructionsModal}
                                                            />
                                                        </div>
                                                    )}

                                                    <div className={"text-primary"}>
                                                        <FormattedMessage id={"order.deliveryTimeDisclaimer"} />
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <div className="mb-3 mb-4">
                                            <label className="form-label mb-3" htmlFor="order-comments">
                                                {/* <span className="badge bg-info fs-xs me-2">Note</span> */}
                                                <span className="fw-medium">
                                                    <FormattedMessage id={"shoppingCart.comment"} />
                                                </span>
                                            </label>
                                            <div className={"position-relative"}>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: "6rem" }}
                                                    disabled={isAddOrderLoading}
                                                    value={state.comment ?? cartComment}
                                                    onChange={onCommentFieldChange}
                                                />
                                                <div
                                                    className={"position-absolute"}
                                                    style={{ height: "0.875rem", width: "0.875rem", right: "0.5rem", top: "0.125rem" }}
                                                >
                                                    {getCartCommentState()}
                                                </div>
                                            </div>
                                        </div>
                                        {tryOrder?.outOfStockProducts.length ? (
                                            <div className={"mb-3 d-flex align-items-center"}>
                                                <CartzillaIcon icon={CartzillaIconName.Announcement} className={"me-2 fs-base align-middle"} />
                                                <FormattedMessage id={"orderContents.amountOfSomeProductsSmallerThanStock"} />
                                            </div>
                                        ) : null}
                                        {tryOrder?.unavailableProducts.length ? (
                                            <div className={"mb-3 d-flex align-items-center"}>
                                                <CartzillaIcon icon={CartzillaIconName.Announcement} className={"me-2 fs-base align-middle"} />
                                                <FormattedMessage id={"orderContents.someProductsAreUnavailable"} />
                                            </div>
                                        ) : null}
                                        <LoadingSpinnerButton
                                            size={"sm"}
                                            className={"w-100 btn-shadow"}
                                            isLoading={isAddOrderLoading}
                                            icon={<CartzillaIcon icon={CartzillaIconName.Card} className={"fs-base"} />}
                                            onClick={sendOrder}
                                            disabled={isSubmitDisabled}
                                        >
                                            <FormattedMessage id={"shoppingCart.submit"} />
                                        </LoadingSpinnerButton>
                                        {pendingInvoices.length > 0 &&
                                        !(!state.isShipmentInstructionsConfirmed || isAnyCartProductArchived() || isAddOrderError) ? (
                                            <div className={"fs-sm mt-3 text-warning"}>
                                                <FormattedMessage id={"pendingInvoiceWarning.message"} />
                                            </div>
                                        ) : null}
                                        {!state.isShipmentInstructionsConfirmed && (
                                            <div className={"mt-3"}>
                                                <FormattedMessage id={"order.confirmShipmentInstructionsDisclaimer"} />
                                            </div>
                                        )}
                                        {isAnyCartProductArchived() && (
                                            <div className={"mt-3 text-primary d-flex align-items-center"}>
                                                <CartzillaIcon icon={CartzillaIconName.Announcement} className={"me-2 fs-base align-middle"} />
                                                <FormattedMessage id={"orderContents.someProductsArchivedDisclaimer"} />
                                            </div>
                                        )}
                                        {isAddOrderError && (
                                            <div className={"mt-3 text-primary d-flex align-items-center"}>
                                                <CartzillaIcon icon={CartzillaIconName.Announcement} className={"me-2 fs-base align-middle"} />
                                                <FormattedMessage
                                                    id={"failedSubmit.submit"}
                                                    values={{
                                                        errorCode:
                                                            addOrderError && "data" in addOrderError && isApiError(addOrderError.data)
                                                                ? addOrderError.data.code
                                                                : null,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </LoadingWrapper>
        </>
    );
};
